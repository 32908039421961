/* css/RaceResultRow.module.css */
.placement {
  flex: 0 0 8%;
  text-align: center;
}

.name {
  margin: 0 1rem;
  flex: 1 0 40%;
  text-align: left;
}

/* .comment {
  
} */

.nonStandardRuleset {
  color: var(--color-error);
  font-weight: 500;
  flex: 0 0 20%;
}

.time {
  flex: 0 0 15%;
  text-align: center;
}

.delta {
  flex: 0 0 10%;
  text-align: center;
  font-size: .75rem;
}

.positive {
  color: var(--color-success)
}

.negative {
  color: var(--color-error)
}