ul.table {
  display: flex;
  flex-direction: column;
  list-style: none;
  width: 100%;
  min-width: 350px;

  background-color: var(--element-border-color);
  border: 1px solid black;
  border-radius: var(--border-radius);
}

ul.table > li {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  column-gap: 1rem;

  width: 100%;
  text-wrap: wrap;
  padding: 1rem;
  
  border-bottom: 1px solid #676767;
  background-color: rgba(255, 255, 255, .05);
}

ul.table > li:hover {
  background-color: rgba(255, 255, 255, 0.10);
}

ul.table > li:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  border: none;
  background-color: rgba(0,0,0,0);
}

ul.table > li:last-child {
  border: none;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

ul.table > li.crow {
  display: flex;
  flex-direction: column;
  padding: 0;
  user-select: none;
  cursor: pointer;
}

ul.table > li.crow:hover {
  border: solid 1px white;
  margin: -1px -1px 0 -1px;
}

.iconStyle {
  vertical-align: middle;
}

.settingName {
  padding: 0.5rem 1rem;
}

.optionBlock {
  background-color: rgba(255, 255, 255, .05);
  padding: 0.5rem 0 1rem 1rem;
}

.optionWeight {
  padding: 0 0.5rem;
  display: inline-block;
  width: 4em;
}

@media ( width <= 600px ) {

}