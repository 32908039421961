/* css/Logo.module.css */
.logoArea {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 1rem;
}

.logo {

}

.logoText {
  padding: 21px 0 12px;
}