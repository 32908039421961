/* css/Loading.module.css */
.container {
  width: 100%;
  height: 20rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.text {
  font-weight: 500;
  font-size: 2.5rem;
}

.graphic {
  width: 100%;
  height: 60%;
  transform-origin: 50% 66.7%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}