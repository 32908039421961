/* global.css */
*, ::before, ::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --font-color-main: #d5d5d5;
  --font-color-secondary: #8c898c;
  --webpage-bg-color: #313131;
  --element-bg-color-base: #212121;
  --element-bg-color-hover: #303030;
  --element-border-color: #151515;
  --element-emphasis-color: #22a7f0;

  --color-error: #c21807;
  --color-success: #71C562;

  /* Default gap between elements */
  --gap: 2em;
  --element-primary-width: 620px;
  --border-radius: 8px;
}

html {
  font-size: 16px;
}

body {
  color: var(--font-color-main);
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: var(--webpage-bg-color);
  background-image: url("../../public/images/bg_image.png");
  margin: 0;
}

main {
  padding: var(--gap);
  display: flex;
  flex-flow: row wrap;
  gap: var(--gap);
}