/* components/Table/Row/UnqualifiedRow.module.css */
.name {
  text-align: left;
  margin: 0 1rem;
}

.remaining {
  color: var(--font-color-secondary);
  font-size: .9rem;
  margin: 0 1rem 0 auto;
}