/* components/Table/Row/QualifiedRow.module.css */
.placement {
  flex: 0 0 8%;
  text-align: center;
}

.name {
  margin: 0 1rem;
  flex: 1 0 40%;
  text-align: left;
}

.rating {
  flex: 0 0 12%;
  text-align: center;
}

.extraData {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 0 0 15%;
  justify-content: center;
  margin:  0 1rem;
}

.finishes {
  line-height: 1rem;
  font-size: 1rem;
  color: #90ee90;
}

.entries {
  line-height: 1;
  font-size: .75rem;
  color: #bdc3c7;
}