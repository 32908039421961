/* css/NavBar.module.css */
.header {
  display: flex;
  z-index: 2;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 2rem 0 2rem;
  margin: 0;
  background-color: var(--element-border-color);
  border-bottom: 1px solid #676767;
  font-size: 1.2em;
}

.navigationArea {
  display: flex;
  flex-direction: row;
}

.mainNav {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
  display: flex;
  height: 100%;
}