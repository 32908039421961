/* components/Table/Table.module.css */
.table {
  display: block;
  list-style: none;
  margin: 0 0 0 0;
  padding: 0;
  background: var(--element-bg-color-base);
  border: 5px solid var(--element-border-color);
  border-radius: 8px;
  width:var(--element-primary-width);
  height: fit-content;
  flex: 0 0 auto;
}

.header {
  display: flex;
  padding: .5rem 1rem;
  background-color: var(--element-border-color);
  justify-content: space-between;
  height: 40px;
}

.primaryHeader {
  margin: 0;
}

.secondaryHeader {
  color: var(--font-color-secondary);
  margin: 0;
  font-size: .9rem;
}

.button {
  border-radius: 4px;
  background-color: var(--element-emphasis-color);
  border: none;
  color: black;
  font-weight: 900;
  text-align: center;
  font-size: 1em;
  padding: 8px 3em 32px;
  margin: -0.5em -1em 0 0;
  transition: all 0.5s;
  cursor: pointer;
}

.button:hover, .button:disabled {
  background-color: #054a7f;
}

.button:disabled {
  cursor: wait;
}

.row {
  display: flex;
  border-top: 1px solid #000000;
  line-height: 2rem;
  padding: 5px;
  justify-content: flex-start;
}

.row:hover {
  border-left: 5px solid var(--element-emphasis-color);
  background-color: var(--element-bg-color-hover);
  margin: 0 0 0 -5px;
}

.rtlink {
  color: var(--font-color-main)
}

.rtlink:hover {
  color: white;
  border: dashed var(--element-emphasis-color)  1px;
  padding: 2px;
  margin: -3px;
}