/* css/RacetimeName.module.css */
.name, .discriminator {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
}

.discriminator {
  color: var(--font-color-secondary);
  font-size: .8rem;
}